import { Actions, Events, Pages } from '@/enums/eventos'
import { trackEvent } from '@/lib/tracking'
import { Left, Right } from '@repo/icons'
import { RefObject, useState } from 'react'

export interface Props {
  scrollDiv: RefObject<HTMLDivElement>
  sectionName: string
  speedWeight: number
  clickLimit: number
}

const CarouselControl: React.FC<Props> = ({
  scrollDiv,
  sectionName,
  speedWeight,
  clickLimit,
}) => {
  const [carouselIndex, setCarouselIndex] = useState<number>(0)

  const onCarouselClick = (direction: string) => {
    if (!scrollDiv.current) return

    const isDirectionRight = direction === 'right'

    const scrollOffset = isDirectionRight
      ? scrollDiv.current?.scrollWidth / speedWeight
      : -scrollDiv.current?.scrollWidth / speedWeight
    scrollOffset > 0
      ? setCarouselIndex(carouselIndex + 1)
      : setCarouselIndex(carouselIndex - 1)
    if (scrollDiv.current) {
      scrollDiv.current.scrollBy({ left: scrollOffset, top: 0, behavior: 'smooth' })
    }

    trackEvent(Events.CAROUSEL_CONTROL, {
      page: Pages.HOMEPAGE,
      action: Actions.CLICKED,
      label: `clicou em seta pra ${
        isDirectionRight ? 'direita' : 'esquerda'
      } em ${sectionName}`,
    })
  }

  return (
    <>
      <button
        type="button"
        className="w-14 h-14 bg-primary-400 rounded-full flex justify-center items-center"
        onClick={() => onCarouselClick('left')}
        disabled={carouselIndex === 0}
      >
        <Left size={24} className="fill-white" />
      </button>
      <button
        type="button"
        className="w-14 h-14 bg-primary-400 rounded-full flex justify-center items-center ml-4"
        onClick={() => onCarouselClick('right')}
        disabled={carouselIndex === clickLimit}
      >
        <Right size={24} className="fill-white" />
      </button>
    </>
  )
}

export default CarouselControl
