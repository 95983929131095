import { settingsOperadoras } from '@/operadoras-settings'

export const metadescriptionDescriptionContent = (operadoraSaudeEnv: string) => {
  const currentSettings = settingsOperadoras[operadoraSaudeEnv || 'main']
  if (operadoraSaudeEnv === 'main') {
    return 'Agende sua consulta e seja atendido sem atrasos, com os melhores profissionais'
  } else {
    return `Agende sua consulta e seja atendido sem atrasos, com os melhores profissionais da ${currentSettings?.text?.operatorName}`
  }
}

export const metaTitleContent = (operadoraSaudeEnv: string) => {
  const currentSettings = settingsOperadoras[operadoraSaudeEnv || 'main']
  if (operadoraSaudeEnv === 'main') {
    return 'Consulta médica de qualidade e na hora marcada | Saludia'
  } else {
    return `Sua consulta na ${currentSettings?.text?.operatorName} | Saludia`
  }
}

export const getFavicon = (operadoraSaudeEnv: string) => {
  return `/favicons/${operadoraSaudeEnv}.png` || '/favicon.ico'
}
