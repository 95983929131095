import {
  getFavicon,
  metadescriptionDescriptionContent,
  metaTitleContent,
} from '@/lib/metadescriptions'
import { subdomainEnv } from '@/operadoras-settings'
import Head from 'next/head'
import { useRouter } from 'next/router'

interface Props {
  title?: string
  canonical?: string
  noFollow?: boolean
}
const CommonHead: React.FC<Props> = ({ title, canonical, noFollow }) => {
  const { query } = useRouter()
  const operadoraSaudeEnv = subdomainEnv[query?.subdomain as string]
  return (
    <Head>
      <title>{title || metaTitleContent(operadoraSaudeEnv)}</title>
      <meta
        name="description"
        content={metadescriptionDescriptionContent(operadoraSaudeEnv)}
      />
      <link rel="icon" href={getFavicon(operadoraSaudeEnv)} type="image/png" />
      <meta
        name="viewport"
        id="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=yes"
      />
      {canonical && <link rel="canonical" href={canonical} />}
      {noFollow && <meta name="robots" content="noindex, nofollow" />}
    </Head>
  )
}

CommonHead.displayName = 'CommonHead'

export default CommonHead
