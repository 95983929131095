import CommonHead from '@/components/CommonHead'
import HomeDifferentials from '@/components/home/sections/HomeDifferentials'
import HomeFooter from '@/components/home/sections/HomeFooter'
import HomeHeader from '@/components/home/sections/HomeHeader'
import HomeHero from '@/components/home/sections/HomeHero'
import HomeLocation from '@/components/home/sections/HomeLocation'
import HomeStepByStep from '@/components/home/sections/HomeStepByStep'
import HomeContact from '@/components/home/sections/HomeContact'
import { IOperadoraSettings } from '@/interfaces/operadora-settings'
import { Building, CalendarTime, Love, Medical, Search } from '@repo/icons'
import { useRef } from 'react'

interface Props {
  currentSettings: IOperadoraSettings
  appVersion: boolean
}

const OperatorHomePage: React.FC<Props> = ({ currentSettings, appVersion = false }) => {
  const differentialRef = useRef<HTMLDivElement>(null)
  const stepByStepRef = useRef<HTMLDivElement>(null)
  const locationRef = useRef<HTMLDivElement>(null)

  const stepByStepBullets = [
    {
      Icon: Search,
      text: 'Clique no tipo de consulta que deseja para iniciar',
    },
    {
      Icon: Medical,
      text: 'Escolha a especialidade que você está procurando',
    },
    {
      Icon: Building,
      text: 'Se sua consulta for presencial, escolha uma de nossas 10 unidades em São Paulo',
    },
    {
      Icon: CalendarTime,
      text: 'Selecione um dia e horário para a sua consulta',
    },
    {
      Icon: Love,
      text: 'Tenha a melhor experiência em consultório até agora!',
    },
  ]

  const headerMenuList = [
    {
      ref: differentialRef,
      text: 'Diferenciais',
      eventLabel: 'clicou na âncora de diferenciais',
    },
    {
      ref: stepByStepRef,
      text: 'Como usar',
      eventLabel: 'clicou na âncora de como usar',
    },
    {
      ref: locationRef,
      text: 'Localidades',
      eventLabel: 'clicou na âncora de localidades',
    },
  ]

  return (
    <div className="mx-auto">
      <CommonHead />

      <HomeHeader menuList={headerMenuList} />

      <main className="container container-home mx-auto my-0">
        <HomeHero
          title="Sua consulta na"
          currentSettings={currentSettings}
          appVersion={appVersion}
        />

        <div className="mb-14 xl:mb-40">
          <HomeDifferentials differentialRef={differentialRef} />
        </div>

        <HomeStepByStep
          stepByStepRef={stepByStepRef}
          stepByStepBullets={stepByStepBullets}
        />

        <HomeLocation locationRef={locationRef} />
      </main>

      <HomeFooter />

      <HomeContact currentSettings={currentSettings} />
    </div>
  )
}

export default OperatorHomePage
