import Logo from '@/components/Logo'
import { CpsLink } from 'corpus'
import Link from 'next/link'

const HomeFooter: React.FC = () => {
  return (
    <footer className="container container-home block my-0 mx-auto">
      <div className="relative mt-6 mb-2">
        <Logo logoVersion="main" />
      </div>
      <div className="flex flex-col xl:flex-row xl:justify-between gap gap-y-6 xl:gap-0 mb-8 ">
        <p className="text-neutral-500 font-medium xl:text-center text-sm">
          © Todos os direitos reservados.
        </p>

        <CpsLink
          as={Link}
          href="/declaracao-privacidade"
          underline
          target="_blank"
          color="primary"
        >
          Declaração de Privacidade
        </CpsLink>
      </div>
    </footer>
  )
}

export default HomeFooter
