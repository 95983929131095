import Link from 'next/link'
import { Whatsapp, Phone } from '@repo/icons'
import { CpsLink } from 'corpus'
import { trackEvent } from '@/lib/tracking'
import { Actions, Events } from '@/enums/eventos'
import { FormasDeContato } from '@/enums/formas-de-contato'
import { IOperadoraSettings } from '@/interfaces/operadora-settings'

interface Props {
  currentSettings: IOperadoraSettings
}

const HomeContact: React.FC<Props> = ({ currentSettings }) => {
  const { WHATSAPP_NUMBER, PHONE_NUMBER } = FormasDeContato
  const WHATSAPP_CHAT_LINK = `https://api.whatsapp.com/send?phone=55${WHATSAPP_NUMBER.replace(/[+-\s]/g, '')}&text=Ol%C3%A1!%20Preciso%20de%20ajuda%20com%20agendamento%20Saludia.`

  return (
    <section className="container container-home flex flex-col gap-4 my-4 mx-auto">
      <div className="relative">
        <h3 className="font-normal text-sm mb-2 xl:text-lg">
          Precisa falar com a gente?
        </h3>
        <CpsLink
          as={Link}
          href={`tel:+55${PHONE_NUMBER.replace(/[+-\s]/g, '')}`}
          underline
          color="primary"
          Icon={Phone}
          iconPosition="start"
          onClick={() =>
            trackEvent(Events.CONTACT_LINK, {
              action: Actions.CLICKED,
              label: 'telefone',
              operatorIds: currentSettings.operatorsId,
            })
          }
        >
          {PHONE_NUMBER}
        </CpsLink>
      </div>
      <div className="relative">
        <h3 className="font-normal text-sm mb-2 xl:text-lg">Prefere WhatsApp?</h3>
        <CpsLink
          as={Link}
          href={WHATSAPP_CHAT_LINK}
          underline
          target="_blank"
          color="primary"
          Icon={Whatsapp}
          iconPosition="start"
          onClick={() =>
            trackEvent(Events.CONTACT_LINK, {
              action: Actions.CLICKED,
              label: 'whatsapp',
              operatorIds: currentSettings.operatorsId,
            })
          }
        >
          {WHATSAPP_NUMBER}
        </CpsLink>
      </div>
    </section>
  )
}

export default HomeContact
