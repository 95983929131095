import { LocationMarker, Love, ShieldCheck } from '@repo/icons'
import Image from 'next/image'
import { RefObject } from 'react'

interface Props {
  differentialRef: RefObject<HTMLDivElement>
}

const HomeDifferentials: React.FC<Props> = ({ differentialRef }) => {
  const differentialBullets = [
    {
      Icon: LocationMarker,
      text: 'Unidades na Vila Mariana, Paulista e Pinheiros têm ótima localização e acesso ao metrô',
    },
    {
      Icon: ShieldCheck,
      text: 'Mais de 90% dos nossos atendimentos são feitos no horário marcado',
    },
    {
      Icon: Love,
      text: 'Ambiente aconchegante, internet super rápida, ar condicionado, café e todo o conforto para a sua consulta.',
    },
  ]

  return (
    <>
      <section ref={differentialRef}>
        <div className="mb-8 xl:mb-14 xl:text-center">
          <h2 className=" text-primary-500 font-medium text-sm xl:text-lg mb-2 uppercase">
            Diferenciais
          </h2>

          <p className="text-neutral-600 font-semibold leading-[30px] text-2xl xl:text-3xl xl:max-w-[548px] xl:mx-auto xl:leading-[42px]">
            Conheça alguns dos nossos benefícios e agende sua consulta
          </p>
        </div>

        <div className="xl:flex xl:justify-evenly mb-8 xl:mb-16">
          {differentialBullets.map(({ Icon, text }, i) => (
            <div className="flex xl:block xl:gap-14 mb-8 xl:mb-0" key={i}>
              <div className="flex-shrink-0 bg-primary-50 rounded-xl w-16 h-16 xl:w-[92px] xl:h-[92px] flex items-center justify-center xl:mb-6">
                <div className="block xl:hidden">
                  <Icon size={32} className="fill-primary-500" />
                </div>
                <div className="hidden xl:block">
                  <Icon size={56} className="fill-primary-500" />
                </div>
              </div>

              <div className="xl:max-w-[390px] ml-3 xl:ml-0 my-auto whitespace-pre-wrap font-medium leading-5 xl:leading-7 text-md xl:text-xl text-neutral-600">
                {text}
              </div>
            </div>
          ))}
        </div>
      </section>

      <section>
        <div className="relative">
          <div className="flex justify-center xl:hidden">
            <Image
              src="/images/mobile/office.png"
              alt="Ambiente do consultório Porto Seguro"
              width={328}
              height={312}
            />
          </div>
          <div className="justify-center hidden xl:flex">
            <Image
              src="/images/desktop/office.png"
              alt="Ambiente do consultório Porto Seguro"
              width={1256}
              height={442}
            />
          </div>

          <div className="w-screen ml-[calc(50%-50vw)] h-[116px] xl:h-[256px] absolute bottom-0 bg-primary-50 -z-10"></div>
        </div>
      </section>
    </>
  )
}

export default HomeDifferentials
