const getLocation = (device = 'mobile') => [
  {
    title: 'Vila Mariana',
    image: `/images/${device}/unidades/vila-mariana.png`,
    alt: 'Imagem da unidade vila mariana',
    description: 'R. Domingos de Morais, 2781 - 14º andar - Vila Mariana, São Paulo - SP',
  },
  {
    title: 'Paulista',
    image: `/images/${device}/unidades/paulista.png`,
    alt: 'Imagem da unidade Paulista',
    description: 'Av. Paulista, 2064 - 21º andar - Bela Vista, São Paulo - SP',
  },
  {
    title: 'Pinheiros',
    image: `/images/${device}/unidades/pinheiros.png`,
    alt: 'Imagem da unidade Pinheiros',
    description: 'Av. Faria Lima, 1461 - 6º andar - Pinheiros - São Paulo - SP',
  },
  {
    title: 'Vila Olímpia',
    image: `/images/${device}/unidades/vila-olimpia.png`,
    alt: 'Imagem da unidade Vila Olímpia',
    description: 'R. Gomes de Carvalho, 1356 - 5º Andar - Vila Olímpia, São Paulo - SP',
  },
  {
    title: 'Brigadeiro',
    image: `/images/${device}/unidades/brigadeiro.png`,
    alt: 'Imagem da unidade Brigadeiro',
    description: 'R. Cincinato Braga, 340 - 6º Andar - Bela Vista, São Paulo - SP',
  },
  {
    title: 'Angélica',
    image: `/images/${device}/unidades/angelica.png`,
    alt: 'Imagem da unidade Angélica',
    description: 'Av. Angélica, 2491 - 9º andar - Bela Vista, São Paulo - SP',
  },
  {
    title: 'Itaim',
    image: `/images/${device}/unidades/itaim.png`,
    alt: 'Imagem da unidade Itaim',
    description: 'Rua Joaquim Floriano, 413 - 9º andar, São Paulo - SP',
  },
  {
    title: 'Brigadeiro Psico',
    image: `/images/${device}/unidades/brigadeiro-psico.png`,
    alt: 'Imagem da unidade Brigadeiro Psico',
    description: 'R. Cincinato Braga, 340 - 5º Andar - Bela Vista, São Paulo - SP',
  },
  {
    title: 'Market Place',
    image: `/images/${device}/unidades/market-place.png`,
    alt: 'Imagem da unidade Market Place',
    description:
      'Av. Dr. Chucri Zaidan, 940 - 3º andar, Torre II - Vila Cordeiro, São Paulo - SP',
  },
  {
    title: 'Tatuapé',
    image: `/images/${device}/unidades/tatuape.png`,
    alt: 'Imagem da unidade Tatuapé',
    description: 'R. Vilela, 665 - 8º Andar - Tatuapé, São Paulo - SP',
  },
]

const LOCATIONS_MOBILE = getLocation('mobile')
const LOCATIONS_DESKTOP = getLocation('desktop')

export { LOCATIONS_MOBILE, LOCATIONS_DESKTOP }
