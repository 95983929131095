import type { SVGIconProps } from "@repo/icons";
import clsx from "clsx";
import type { FunctionComponent } from "react";
import { forwardRef, memo } from "react";

interface ICpsLink extends React.ComponentPropsWithoutRef<"a"> {
  href: string;
  Icon?: FunctionComponent<SVGIconProps>;
  iconPosition?: "start" | "end";
  disabled?: boolean;
  underline?: boolean;
  color?: "helper" | "primary";
  onClick?: (e: any) => void;
  children: string;
  as?: React.ElementType;
}

/**
 * `CpsLink` is a component that renders a link.
 *
 * @remarks
 * This component is part of the CPS UI library.
 *
 * @example
 * Here is a basic usage example:
 * ```tsx
 * <CpsLink href="https://www.google.com">Google</CpsLink>
 * ```
 * @param href - The URL of the link.
 * @param Icon - The icon to be displayed on the left side of the link.
 * @param iconPosition - The position of the icon on the left side of the link.
 * @param disabled - Whether the link is disabled.
 * @param underline - Whether the link should have an underline.
 * @param color - The color of the link.
 * @param onClick - The function to be called when the link is clicked.
 * @param children - The text to be displayed on the link.
 * @param as - The element the component should be rendered.
 *
 * @returns The `CpsLink` component.
 */
export const CpsLink = forwardRef<HTMLAnchorElement, ICpsLink>(
  (
    {
      Icon,
      iconPosition,
      children,
      disabled,
      underline,
      color = "helper",
      onClick,
      as: Component = "a",
      ...props
    },
    ref,
  ) => {
    const colorClasses = {
      primary: {
        text: "text-primary-400 hover:text-primary-700 focus:text-primary-700",
        icon: "group-hover:fill-primary-700 group-focus:fill-primary-700 fill-primary-400",
      },
      helper: {
        text: "text-helper-500 hover:text-helper-700 focus:text-helper-700",
        icon: "fill-helper-500 group-hover:fill-helper-700 group-focus:fill-helper-700",
      },
    };

    const textColorClasses = colorClasses[color].text;
    const cpsIconColorClasses = colorClasses[color].icon;

    const disableClasses = clsx(
      disabled ? "pointer-events-none text-neutral-300" : textColorClasses,
    );

    const commonClasses =
      "group flex items-center text-sm font-medium leading-5 transition-all duration-200";

    const underlineClasses = "underline underline-offset-2";

    const cpsIconClasses = clsx(
      iconPosition === "start" ? "mr-2" : "ml-2",
      !disabled && cpsIconColorClasses,
      "transition-all duration-200",
    );

    return (
      <Component
        ref={ref}
        {...props}
        className={clsx(
          disabled ? "pointer-events-none" : "cursor-pointer",
          "flex w-fit",
        )}
      >
        <div className={clsx(commonClasses, disableClasses)} onClick={onClick}>
          {Icon && iconPosition === "start" ? (
            <Icon className={cpsIconClasses} size={16} />
          ) : null}
          <span className={clsx(underline && underlineClasses)}>{children}</span>
          {Icon && iconPosition === "end" ? (
            <Icon className={cpsIconClasses} size={16} />
          ) : null}
        </div>
      </Component>
    );
  },
);

const MemoCpsLink = memo(CpsLink);
MemoCpsLink.displayName = "CpsLink";

export default MemoCpsLink;
