import Logo from '@/components/Logo'
import { Actions, Events, Pages } from '@/enums/eventos'
import { IOperadoraSettings } from '@/interfaces/operadora-settings'
import { trackEvent } from '@/lib/tracking'
import { Medical, Video } from '@repo/icons'
import { CpsButton } from 'corpus'
import Image from 'next/image'
import Link from 'next/link'
import { useState } from 'react'

interface Props {
  title: string
  corretoraEnv?: boolean
  currentSettings: IOperadoraSettings
  appVersion: boolean
}

const HomeHero: React.FC<Props> = ({
  title,
  corretoraEnv = false,
  currentSettings,
  appVersion = true,
}) => {
  const [isLoading, setIsLoading] = useState<Record<string, boolean>>({
    consulta: false,
    teleconsulta: false,
  })

  const handleOnClickTipoConsulta = (
    eventLabel: string,
    tipoConsulta: 'consulta' | 'teleconsulta',
  ) => {
    trackEvent(Events.APPOINTMENT_TYPE, {
      page: Pages.HOMEPAGE,
      action: Actions.CLICKED,
      type: tipoConsulta,
      label: eventLabel,
    })

    const newRecord: Record<string, boolean> = {
      consulta: false,
      teleconsulta: false,
    }

    newRecord[tipoConsulta] = true

    setIsLoading(newRecord)
  }

  const { operatorName, operatorFor } = currentSettings.text

  return (
    <section
      className={`mb-10 xl:flex xl:justify-center relative ${
        !appVersion ? 'xl:items-center' : ''
      }`}
    >
      <div className="xl:w-[620px] 2xl:w-auto">
        {!appVersion && (
          <>
            <h1 className="mb-4 xl:mb-6 text-neutral-600 text-3xl font-semibold leading-9 xl:text-[56px] xl:leading-[56px]">
              {title} {corretoraEnv ? null : <br />}
              <span className="text-primary-400">{operatorName}</span>
            </h1>
            <p className="mb-6 text-neutral-500 text-xl font-medium leading-7 xl:text-4xl xl:leading-[56px]">
              no horário marcado e nas melhores regiões de SP
            </p>

            <div className="mb-14 hidden xl:block text-neutral-500 font-medium text-xl xl:leading-[26px]">
              <p>
                Agende sua consulta e seja atendido sem atrasos, com os melhores
                profissionais.
              </p>
              <p>
                Ah, tudo isso através da sua rede credenciada e coberto {operatorFor}.
              </p>
            </div>
          </>
        )}
        {appVersion && (
          <>
            <h1 className="mb-5 text-3xl text-neutral-600 font-semibold leading-9 xl:text-[56px] xl:leading-[56px] xl:pt-24">
              Agende sua consulta com profissionais da{' '}
              <span className="text-primary-400">
                {currentSettings.text.operatorAppText}
              </span>
            </h1>
            <div className="mb-5 text-neutral-500 text-sm">
              <p>
                Serviço oferecido por <Logo logoVersion="main" height={35} width={100} />
              </p>
            </div>
          </>
        )}

        <div className="w-full mb-4 flex flex-col gap-2 xl:mb-0 xl:flex-row xl:gap-8">
          {currentSettings.journey.inPerson && (
            <Link href="/consulta" className="w-full xl:max-w-[260px]">
              <CpsButton
                Icon={Medical}
                fullWidth
                loading={isLoading['consulta']}
                onClick={() =>
                  handleOnClickTipoConsulta(
                    'clicou no botão agendar consulta',
                    'consulta',
                  )
                }
              >
                Consulta presencial
              </CpsButton>
            </Link>
          )}

          {currentSettings.journey.online && (
            <Link href="/teleconsulta" className="w-full xl:max-w-[260px]">
              <CpsButton
                Icon={Video}
                fullWidth
                loading={isLoading['teleconsulta']}
                onClick={() =>
                  handleOnClickTipoConsulta(
                    'clicou no botão agendar teleconsulta',
                    'teleconsulta',
                  )
                }
              >
                Teleconsulta
              </CpsButton>
            </Link>
          )}
        </div>
      </div>
      <div className="relative xl:shrink-0 xl:ml-[66px]">
        <div className="h-[330px] w-[236px] xl:w-[514px] xl:h-[713px]">
          <Image
            src="/images/desktop/hero-img.png"
            alt="Consulta Porto Seguro"
            className="block my-0 mx-auto z-10 object-contain object-top"
            fill={true}
            priority
          />
        </div>
      </div>{' '}
    </section>
  )
}

export default HomeHero
