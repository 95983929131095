import CarouselControl from '@/components/CarouselControl'
import { Cookies } from '@/enums/cookies'
import { IOperadora } from '@/interfaces/operadora'
import { Cookie } from '@/lib/cookies'
import Image from 'next/image'
import Link from 'next/link'
import { RefObject, useEffect, useRef, useState } from 'react'

interface Props {
  operatorsRef: RefObject<HTMLDivElement>
  allOperadoras: IOperadora[]
}

const HomeOperators: React.FC<Props> = ({ operatorsRef, allOperadoras }) => {
  const scrollDiv = useRef<HTMLDivElement>(null)

  const [utms, setUtms] = useState<string>('')

  useEffect(() => {
    const utms = JSON.parse(Cookie.get(Cookies.UTMS) || '{}')
    const utmsQuery = `${new URLSearchParams(utms).toString()}`

    setUtms(utmsQuery)
  }, [])

  const buildRedirectUrl = (url: string) => {
    if (utms && utms.length > 0) {
      return `${url}?${utms}`
    }

    return url
  }

  return (
    <section className="relative -mx-6 xl:pb-20 py-14 xl:pt-20" ref={operatorsRef}>
      <div className="xl:flex justify-center items-center xl:items-baseline xl:justify-start">
        <div className="mb-8 xl:mb-0 px-6 xl:px-0 w-[377px] xl:shrink-0 xl:ml-6 2xl:ml-0 self-start">
          <h2 className=" text-primary-500 font-medium text-sm mb-2 xl:text-lg uppercase">
            Convênios
          </h2>
          <p className="text-neutral-600 font-semibold leading-[32px] text-2xl xl:text-3xl xl:leading-[42px] xl:max-w-[548px] mb-2 xl:mb-6">
            Médicos credenciados diretamente com seu convênio.
          </p>
          <p className="text-neutral-500 leading-5 xl:leading-7 text-md xl:text-xl xl:mb-14">
            Esqueça pedidos de reembolso e preocupe-se apenas com a sua saúde.
          </p>

          {allOperadoras && allOperadoras.length > 3 && (
            <div className="hidden xl:flex">
              <CarouselControl
                scrollDiv={scrollDiv}
                sectionName="operadoras"
                speedWeight={2}
                clickLimit={1}
              />
            </div>
          )}
        </div>

        <div className="xl:ml-14 xl:w-full h-auto xl:grow-1">
          <div
            className="xl:w-[1178px] xl:pr-[270px] 2xl:pr-[100px] overflow-scroll xl:overflow-hidden flex overflow-y-hidden overflow-x-scroll whitespace-nowrap transition-all duration-300 ease-in-out gap-4 xl:gap-8"
            ref={scrollDiv}
          >
            {allOperadoras
              ? allOperadoras.map((operadora) => (
                  <Link
                    href={buildRedirectUrl(operadora.url)}
                    className={
                      'rounded-50 w-[156px] min-w-[156px] xl:w-[276px] xl:min-w-[276px] h-[222px] xl:h-[334px] bg-white mb-[1px] shadow-marketplaceCard first:ml-6 last:mr-6 xl:first:ml-0 xl:last:mr-0'
                    }
                    key={`operator-card-${operadora.id}`}
                  >
                    <div className="my-4 flex justify-center items-center xl:hidden">
                      <Image
                        className="object-cover rounded-t-50"
                        src={operadora.logo}
                        alt=""
                        height={124}
                        width={124}
                      />
                    </div>

                    <div className="my-10 hidden xl:flex xl:justify-center xl:items-center">
                      <Image
                        className="object-cover rounded-t-50"
                        src={operadora.logo}
                        alt=""
                        height={196}
                        width={196}
                      />
                    </div>
                    <div className="px-6 mb-2">
                      <p className="text-md xl:text-xl font-semibold text-neutral-600 mb-2 text-center whitespace-pre-wrap">
                        {operadora.nomeComercial}
                      </p>
                    </div>
                  </Link>
                ))
              : null}
          </div>
        </div>

        <div className="w-[9999px] ml-[calc(50%-100vw)] h-full absolute bottom-0 bg-primary-50 -z-10 left-0"></div>
      </div>
    </section>
  )
}

export default HomeOperators
