import CarouselControl from '@/components/CarouselControl'
import { LOCATIONS_DESKTOP } from '@/lib/pages/locations'
import Image from 'next/image'
import { RefObject, useRef } from 'react'

interface Props {
  locationRef: RefObject<HTMLDivElement>
}

const HomeLocation: React.FC<Props> = ({ locationRef }) => {
  const scrollDiv = useRef<HTMLDivElement>(null)

  return (
    <section className="relative -mx-6 xl:pb-14 pt-14 xl:pt-[96px]" ref={locationRef}>
      <div className="xl:flex justify-center items-center xl:justify-start">
        <div className="mb-8 xl:mb-0 px-6 xl:px-0 w-[377px] xl:shrink-0 xl:ml-6 2xl:ml-0 self-start">
          <h2 className=" text-primary-500 font-medium text-sm mb-2 xl:text-lg uppercase">
            Localidades
          </h2>

          <p className="text-neutral-600 font-semibold leading-[32px] text-2xl xl:text-3xl xl:leading-[42px] xl:max-w-[548px] mb-2 xl:mb-6">
            Veja e escolha a melhor opção e região para realizar a sua consulta presencial
          </p>

          <p className="text-neutral-500 leading-5 xl:leading-7 text-md xl:text-xl xl:mb-14">
            Todas as unidades estão situadas em regiões próximas às estações e com fácil
            acesso.
          </p>

          <div className="hidden xl:flex">
            <CarouselControl
              scrollDiv={scrollDiv}
              sectionName="localidades"
              speedWeight={8}
              clickLimit={6}
            />
          </div>
        </div>

        <div className="xl:ml-14 xl:w-full h-[430px] xl:h-auto xl:grow-1">
          <div
            className="xl:w-[1178px] xl:pr-[270px] 2xl:pr-[100px] overflow-scroll xl:overflow-hidden flex overflow-y-hidden overflow-x-scroll whitespace-nowrap transition-all duration-300 ease-in-out gap-4 xl:gap-8"
            ref={scrollDiv}
          >
            {LOCATIONS_DESKTOP.map((location, i) => (
              <div
                className={
                  'rounded-50 min-w-[248px] xl:min-w-[464px] xl:w-[464px] h-[371px] xl:h-[586px] bg-white mb-[1px] shadow-marketplaceCard first:ml-6 last:mr-6 xl:first:ml-0 xl:last:mr-0'
                }
                key={i}
              >
                <div className="mb-4 block xl:hidden">
                  <Image
                    className="object-cover rounded-t-50"
                    src={location.image}
                    alt={location.alt}
                    height={231}
                    width={248}
                    quality={20}
                  />
                </div>

                <div className="mb-4 hidden xl:block">
                  <Image
                    className="object-cover rounded-t-50"
                    src={location.image}
                    alt={location.alt}
                    height={432}
                    width={464}
                  />
                </div>
                <div className="px-6 mb-2">
                  <p className="text-md xl:text-xl font-semibold text-neutral-600 mb-2">
                    {location.title}
                  </p>
                  <p className="text-md xl:text-xl leading-5 text-neutral-500 whitespace-pre-wrap xl:leading-7">
                    {location.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="w-[9999px] ml-[calc(50%-100vw)] h-full absolute bottom-0 bg-primary-50 -z-10 left-0"></div>
      </div>
    </section>
  )
}

export default HomeLocation
