import Divider from '@/components/Divider'
import { SVGIconProps } from '@repo/icons'
import Image from 'next/image'
import React, { FunctionComponent, RefObject } from 'react'

interface Props {
  stepByStepRef: RefObject<HTMLDivElement>
  stepByStepBullets: {
    Icon: FunctionComponent<SVGIconProps>
    text: string
  }[]
}

const HomeStepByStep: React.FC<Props> = ({ stepByStepRef, stepByStepBullets }) => {
  return (
    <section className="xl:flex xl:mb-40" ref={stepByStepRef}>
      <div className="xl:shrink-0 xl:max-w-[428px]">
        <div className="mb-8 xl:mb-14">
          <h2 className=" text-primary-500 font-medium text-sm xl:text-lg mb-2 uppercase">
            Como usar
          </h2>
          <p className="text-neutral-600 font-semibold leading-8 text-xl xl:text-3xl xl:leading-[42px] xl:max-w-[548px] xl:mx-auto">
            Veja como é fácil agendar sua consulta
          </p>
        </div>

        <div className="mb-[18x]">
          {stepByStepBullets.map(({ Icon, text }, i) => (
            <div className="mb-6 last-of-type:mb-6 flex" key={i}>
              <div className="flex-shrink-0 bg-primary-50 rounded-xl w-14 h-14 flex items-center justify-center mb-4">
                <Icon size={32} className="fill-primary-500" />
              </div>
              <div className="ml-6 grow">
                <div className="text-md xl:text-lg leading-6 text-primary-700 xl:text-neutral-500 xl:leading-7 mb-6">
                  {text}
                </div>
                {i !== stepByStepBullets.length - 1 && <Divider />}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mb-14 my-0 mx-auto z-10 xl:ml-[72px]">
        <div className="block xl:hidden text-center">
          <Image
            src="/images/mobile/step-by-step.png"
            alt="Como usar para agendar uma consulta"
            height={330}
            width={310}
          />
        </div>

        <div className="hidden xl:block relative">
          <Image
            src="/images/desktop/step-by-step.png"
            alt="Como usar para agendar uma consulta"
            height={603}
            width={796}
          />
        </div>
      </div>
    </section>
  )
}

export default HomeStepByStep
