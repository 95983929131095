import type { SVGIconProps } from "@repo/icons";
import type { FunctionComponent, MouseEventHandler } from "react";
import { forwardRef, memo } from "react";
import { cn } from "@repo/cn";
import { CpsSpinner } from "../CpsSpinner";

export interface CpsButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  size?: "small" | "default";
  Icon?: FunctionComponent<SVGIconProps>;
  rounded?: boolean;
  flat?: boolean;
  fullWidth?: boolean;
  secondary?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
  iconClassName?: string;
  children?: string;
}

const ShowIcon = ({
  icon: Icon,
  iconClasses,
  disabled,
  primaryIconColors,
  size,
}: {
  icon: FunctionComponent<SVGIconProps>;
  iconClasses: string;
  disabled: boolean;
  primaryIconColors: string;
  size: string;
}): JSX.Element => {
  return (
    <Icon
      className={cn(iconClasses, disabled ? "fill-neutral-300" : primaryIconColors)}
      size={size === "small" ? 16 : 24}
    />
  );
};

/**
 * CpsButton component.
 *
 * Forwards ref and renders a button with various props for size, icon,
 * rounded corners, click handler, disabled state, flat style, full width,
 * secondary style, loading state, and type.
 *
 * Handles loading state by conditionally showing spinner and hiding children.
 * Renders icon if provided using ShowIcon component.
 *
 * @param size - the size of the button.
 * @param Icon - the icon to be rendered.
 * @param rounded - whether the button should have rounded corners.
 * @param children - the text to be rendered as the button's children.
 * @param onClick - the click handler for the button.
 * @param disabled - whether the button should be disabled.
 * @param flat - whether the button should have a flat style.
 * @param fullWidth - whether the button should have a full width style.
 * @param secondary - whether the button should have a secondary style.
 * @param loading - whether the button should be loading.
 * @param type - the type of the button.
 * @param ref - the ref to be forwarded to the button.
 * @returns The CpsButton JSX element.
 *
 */
export const CpsButton = forwardRef<HTMLButtonElement, CpsButtonProps>(
  (
    {
      size = "default",
      Icon,
      rounded = false,
      children,
      onClick,
      disabled = false,
      flat = false,
      fullWidth = false,
      secondary = false,
      loading = false,
      type = "button",
      className,
      iconClassName,
      ...props
    },
    ref,
  ): JSX.Element => {
    const roundClass = rounded ? "rounded-70" : "rounded-30";
    const btnSize: Record<string, string> = {
      small: cn(
        "text-sm font-medium",
        children ? "px-6 min-h-[36px] min-w-[96px] py-2" : "p-2",
      ),
      default: cn(
        "font-semibold",
        children ? "px-6 min-h-[52px] min-w-[144px] py-4" : "p-4",
      ),
    };

    const colorClasses = cn(
      secondary
        ? "bg-primary-50 text-primary-50-contrast hover:bg-primary-100"
        : "bg-primary-400 text-primary-400-contrast hover:bg-primary-400",
      "focus:ring-primary-300 focus:ring-2 focus:ring-offset-2 disabled:bg-neutral-50 disabled:text-neutral-300",
    );

    const sizeClasses = btnSize[size];

    const commonClasses =
      "flex text-center justify-center items-center leading-5 select-none	transition-bg duration-200 whitespace-normal";

    const iconClass = "shrink-0";

    const primaryIconColors = cn(
      secondary ? "fill-primary-50-contrast" : "fill-primary-400-contrast",
      iconClassName,
    );

    const buttonClasses = cn(
      sizeClasses,
      colorClasses,
      commonClasses,
      !flat && roundClass,
      fullWidth && "w-full",
      className,
    );

    const isDisabled = disabled || loading;

    return (
      <button
        className={buttonClasses}
        disabled={isDisabled}
        onClick={onClick}
        ref={ref}
        type={type}
        {...props}
      >
        <div className={cn(loading ? "opacity-100" : "opacity-0", "absolute")}>
          <CpsSpinner small />
        </div>

        <div
          className={cn(
            loading ? "opacity-0" : "opacity-100",
            "flex items-center",
            size === "small" ? "gap-1" : "gap-2",
          )}
        >
          {Icon ? (
            <ShowIcon
              disabled={disabled}
              icon={Icon}
              iconClasses={iconClass}
              primaryIconColors={primaryIconColors}
              size={sizeClasses}
            />
          ) : null}
          {children}
        </div>
      </button>
    );
  },
);

CpsButton.displayName = "CpsButton";

const MemoCpsButton = memo(CpsButton);
export default MemoCpsButton;
MemoCpsButton.displayName = "CpsButton";
